import React, { Component } from 'react'
import { graphql } from 'gatsby'
import './AlbumTemplate.scss'

import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import moment from 'moment'
import 'moment/locale/de'
import findIndex from 'lodash/findIndex'
import orderBy from 'lodash/orderBy'
import { getStandardizedDate } from '../utils/DateHelper'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

class AlbumTemplate extends Component {
    state = {
        openGallery: false,
        currentPhotoSlug: null,
        content: {
            images: []
        },
    }

    componentDidMount() {
        const dataPhotos = orderBy(this.props.data.photoAlbum.photos, 'postDate', 'desc')
        const imageItems = dataPhotos.map((photo) => ({
            imageThumbnail: photo.image.localFile.childImageSharp.thumbnailResize.src,
            imageLowRes: photo.image.localFile.childImageSharp.lowImageResize.src,
            imageHighRes: photo.image.localFile.childImageSharp.highImageResize.src,
            width: photo.aspectRatioWidth,
            height: photo.aspectRatioHeight,
            alt: `Kunstwerk mit dem Titel ${photo.title}`,
            ...photo
        }))
        this.setState({ content: { images: imageItems } })
    }

    closeGallery = () => {
        this.setState({
            openGallery: false,
            currentPhotoSlug: null,
        })
    }

    onPhotoPress = (slug) => {
        this.setState({
            openGallery: true,
            currentPhotoSlug: slug,
        })
    }

    toggleGallery = () => {
        this.setState(prevState => ({
            openGallery: !prevState.galleryOpened,
        }))
    }

    getAbsoluteTimeString = (postDateInput) => {
        const postDate = getStandardizedDate(postDateInput)
        moment.locale('de')

        return moment(postDate).format('DD.MM.YYYY')
    }

    getRelativeTimeString = (postDateInput) => {
        const postDate = getStandardizedDate(postDateInput)
        moment.locale('de')

        if (moment().diff(moment(postDate), 'months') < 12) {
            // recent work
            return moment(postDate).format('MMMM YYYY')
            // return moment(postDate).fromNow()   // relative date, e.g. 'vor 2 Monaten'
        } else {
            // work is not recent (older than a year)
            return moment(postDate).format('YYYY')
        }
    }

    getCurrentIndex = () => {
        if (!this.state.currentPhotoSlug) {
            return 0
        } else {
            // return position of currentPhotoSlug in content.images-array
            return findIndex(this.state.content.images, ['slug', this.state.currentPhotoSlug])
        }
    }

    render() {
        const { data, location } = this.props
        const { photoAlbum: { title, body, metaDescription } } = data

        const {
            openGallery,
            content
        } = this.state

        return (
            <Layout location={location}>
                <SEO
                    title={title}
                    description={metaDescription}
                />
                <div className='album bg-white'>
                    <Container>
                        <Jumbotron className='pt-5 mb-5'>
                            <h1 className='text-tint'>{ title }</h1>
                            <p dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }} />
                        </Jumbotron>
                        <Gallery
                            targetRowHeight={400}
                            photos={
                                content.images.map(imageItem => ({
                                    src: imageItem.imageLowRes,
                                    width: imageItem.width,
                                    height: imageItem.height,
                                    imageItem: imageItem
                                }))
                            }
                            renderImage={(renderImageProps) => {
                                const { margin } = renderImageProps
                                const { src, width, height, alt, imageItem } = renderImageProps.photo
                                const { slug, title, postDate } = imageItem
                                return (
                                    <figure key={slug} onClick={() => { this.onPhotoPress(slug) }}
                                        style={{ margin: margin, cursor: 'pointer', overflow: 'hidden', position: 'relative' }}>
                                        <img width={width} height={height}
                                            alt={alt} src={src}
                                            style={{
                                                display: 'block',
                                                // Note the aspect-ratio...
                                                maxWidth: `calc((100vh - 100px) * (${imageItem.width}/${imageItem.height}))`
                                            }} />
                                        <div><div className='interaction-bar'>
                                            <figcaption className='m-3'>
                                                <div className='card-title h5'>{title}</div>
                                                <div className={'text-muted w-100'} title={this.getAbsoluteTimeString(postDate)}>
                                                    <i className='far fa-clock' /> {this.getRelativeTimeString(postDate)}
                                                </div>
                                            </figcaption>
                                        </div></div>
                                    </figure>
                                )
                            }} />
                    </Container>

                    {/* Following code is old implementation, which was using the bootstrap-cards and was replaced with above
                        'Gallery'-component courtesy of 'react-photo-gallery'-dependency.
                    */}
                    {/* <Container>
                        <Row>
                            {
                                content.images ?
                                    content.images.map(imageItem => (
                                        <Col key={imageItem.src} md={4} className='mb-4'>
                                            <AlbumItem
                                                cardImageSrc={imageItem.src}
                                                cardTitle={imageItem.cardTitle}
                                                cardDescription={imageItem.cardDescription}
                                                postDate={imageItem.postDate}
                                                onPress={this.onPhotoPress}
                                            />
                                        </Col>
                                    )) : <React.Fragment />
                            }
                        </Row>
                    </Container> */}
                </div>

                <ModalGateway>
                    {openGallery ? (
                        <Modal onClose={this.closeGallery}>
                            <Carousel currentIndex={this.getCurrentIndex()} views={content.images.map(imageItem => ({
                                caption: `${imageItem.title}${
                                    imageItem.description ? ' // ' + imageItem.description : ''
                                    }`,
                                source: {
                                    fullscreen: imageItem.imageHighRes,
                                    regular: imageItem.imageHighRes,
                                    thumbnail: imageItem.imageThumbnail
                                }
                            }))} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Layout>
        )
    }
}

export default AlbumTemplate

export const pageQuery = graphql`
  query PhotoAlbumBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    photoAlbum: contentfulPhotoAlbum(slug: {eq: $slug}) {
        slug
        title
        metaDescription
        body {
            childMarkdownRemark {
                html
            }
        }
        photos {
            title
            slug
            postDate
            aspectRatioWidth
            aspectRatioHeight
            description
            image {
                localFile {
                    childImageSharp {
                        thumbnailResize: resize(width: 100, height: 67) {
                            src
                        }
                        lowImageResize: resize(width: 800) {
                            src
                        }
                        highImageResize: resize(width: 2048) {
                            src
                        }
                    }
                    publicURL
                }
            }
        }
    }
  }
`
